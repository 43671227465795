<template>
  <section class="container-scroller">
    <div class="row">
      <div class="col-lg-8 grid-margin">
        <h3 class="card-title text-white rounded p-1 mb-1 text-center"
        style=" background-color: #0B5D3F; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);" >
        Détail de la venue
      </h3>


      <div class="card p-2">
        <div class="card-body ">
          <div v-if="showSpinner" class="spinner-border spinner-border-sm text-primary" style="font-size:11px" role="status">
                  <span class="sr-only">Loading...</span>
                </div>


          <table class="table table-bordered">
            <tr> <td> N° Venue</td> <td> <b> {{ movment.iep }}</b> </td> </tr>
            <tr> <td>N° Patient</td> <td> <b>{{ movment.ipp }} </b></td> </tr>
            <tr> <td>Nom & Prénoms</td>  <td><b> {{ movment.lastname }} {{ movment.firstname }} </b>    </td> </tr>
            <tr> <td>Age </td> <td><b>{{ movment.age }} <i>ans</i> </b> </td> </tr>
            <tr> <td>Status </td> <td><b>{{ movment.marital_status }}  </b> </td> </tr>
            <tr> <td>Email </td> <td><b>{{ movment.email }} </b> </td> </tr>
            <tr> <td>Profession </td> <td><b>{{ movment.profession }}  </b> </td> </tr>
            <tr> <td>Téléphone </td> <td>  <b>{{ movment.phone }}  </b></td> </tr>
            <tr> <td>Numéro d'urgence </td> <td>  <b>{{ movment.emergency_contac }}  </b></td> </tr>
            <tr> <td>Date d'arrivée</td> <td><b>{{ movment.human_arrival_date }}</b>  </td> </tr>
            <tr> <td>Motif principal de la venue</td> <td><b>{{ movment.incoming_reason }}</b>  </td> </tr>
            <tr> <td>Service de taitement</td> <td> <b>{{ movment.services_name }}  </b></td> </tr>
            <tr> <td>Date de sortie </td> <td> <b>{{ movment.releasedate }}  </b></td> </tr>
            <tr> <td>Motif de sortie </td> <td> <b>{{ movment.outgoing_reason }}  </b></td> </tr>

          </table>
          <br>

          <div class="mx-auto text-center">

           <router-link to="/movments/list"> <button type="button" class="btn btn-sm btn-danger btn-medpay-green-small  mr-2">
              Retour
            </button></router-link>

          </router-link>
            <button @click="treatement(movment.id)" class="btn btn-success btn-sm mr-2 btn-medpay-green-small ">  Traiter la venue </button></div>
          </div>

        </div>

      </div>
    </div>
  </div>

</section>
</template>
<script>

import Axios from "@/_services/caller.services";

export default {
 // components : {MeasurementComponent},
  props : ["movment_id"],
  data() {
    return {
      movment: {},
      showSpinner : false
    };
  },
  mounted() {
    this.fetchMovemt();
  },

  methods: {
    fetchMovemt() {
      this.showSpinner = true;
      Axios.get("/movments/"+ this.movment_id)
      .then(
        (res) => {
          this.movment = res.data.data;
          this.showSpinner = false;
          console.log(this.movment);
        })
      .catch((error) => {
        console.error(error);
      });
    },

    treatement(id) {
      this.$router.push("/movments/treatement/" + id);
    },
  }
};


</script>
